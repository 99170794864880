namespace $.$$ {
	
	const { rem } = $mol_style_unit

	$mol_style_define( $hyoo_talks_person_avatar, {
		
		// padding: 0,
		
		// Image: {
		// 	width: rem(1.5),
		// 	height: rem(1.5),
		// 	margin: [ rem(.5), 0 ],
		// },
		
		// Name: {
		// 	padding: $mol_gap.text,
		// },
		
	} )
	
}
