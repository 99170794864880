$hyoo_talks_chat_page $mol_page
	messages?val /$hyoo_talks_message
	chat $hyoo_talks_chat
	chat_id \0_0
	embed false
	attr *
		^
		hyoo_talks_chat_page_embed <= embed
	Title $mol_string
		value?val <=> title?val \
		hint @ \Chat title
	tools /
		<= Dump $mol_button_download
			hint @ \Download all messages
			file_name <= dump_name \chat.сsv
			blob <= dump_blob?val null
		<= Joined $mol_check_icon
			hint @ \Joined
			Icon <= Joined_icon $mol_icon_eye
			checked?val <=> joined?val false
		<= Search_toggle $mol_check_icon
			hint @ \Toggle search
			Icon <= Search_toggle_icon $mol_icon_magnify
			checked?val <=> search_enabled?val false
		<= Close null
	head /
		<= Title
		<= Tools
		<= Search $mol_search_jumper
			query?val <=> search?val \
			Root <= Body
			escape?val <=> search_end?val null
	body /
		<= Bubbles $mol_list rows <= bubbles /
	foot <= draft_controls /
		<= Draft_text $mol_textarea
			hint @ \New message..
			value?val <=> draft_text?val \
		<= Speech_toggle $mol_check_icon
			Icon <= Speech_toggle_icon $mol_icon_microphone
			checked?val <=> hearing?val false
		<= Draft_send $mol_button_minor
			title @ \Send
			hint @ \Ctrl+Enter to send
			sub /
				<= Draft_send_icon $mol_icon_arrow_up_bold
			click?val <=> draft_send?val null
	Bubble!id $hyoo_talks_message_bubble
		minimal_height 80
		message <= message!id $hyoo_talks_message
		highlight <= search
	plugins /
		<= Send_key $mol_hotkey
			mod_ctrl true
			key *
				enter?event <=> draft_send?event null
				F?event <=> search_start?event null
