namespace $.$$ {
	
	const { rem } = $mol_style_unit
	
	$mol_style_define( $hyoo_talks_person_settings, {
		
		flex: {
			basis: rem(20),
			shrink: 0,
		},
		
	} )
	
}
