$hyoo_talks $mol_book2
	embed false
	attr *
		^
		hyoo_talks_embed <= embed
	style *
		^
		background-image <= background \
	plugins /
		<= Theme $mol_theme_auto
	Roster $mol_page
		title @ \Talks
		tools /
			<= Chat_new $mol_button_minor
				hint @ \New Chat
				click? <=> chat_new? null
				sub /
					<= Chat_new_icon $mol_icon_plus
		body <= roster_body /
			<= Links_query $mol_search
				query?val <=> links_query?val \
			<= Links $mol_list rows <= links /
		foot /
			<= Online $hyoo_sync_online
				yard <= yard $hyoo_sync_yard<any>
			<= Source $mol_link_source
				uri \https://github.com/hyoo-ru/talks.hyoo.ru
			<= Lights $mol_lights_toggle
			<= Settings_link $mol_link
				arg * settings \
				hint @ \Open Settings
				sub /
					<= Settings_icon $mol_icon_settings
	Settings $hyoo_talks_person_settings
		person <= User $hyoo_talks_person
		tools /
			<= Settings_close $mol_link
				arg * settings null
				sub /
					<= Settings_close_icon $mol_icon_close
	Chat_page* $hyoo_talks_chat_page
		chat <= chat* $hyoo_talks_chat
		embed <= embed
		Close <= Chat_close $mol_link
			arg * chat null
			sub /
				<= Chat_icon $mol_icon_close
	Chat_unread_count* $mol_speck
		value <= chat_unread_count* 0
	Chat_link_title* $mol_dimmer
		haystack <= chat_title* \
		needle <= links_query
	Chat_link* $mol_link
		arg <= chat_arg* *
		sub <= chat_link_sub* /
	unnamed @ \Unnamed
	new_message @ \New Message
